<template>
  <!-- 登录 -->
  <div class="login">

    <div class="loginMain">
      <div class="loginMainRight">
        <div class="loginBox">
          <div class="top-pic">
            <img src="../../assets/login/2820467f-3219-4716-bf47-20f26fdba3d7.png" alt="" />
          </div>
          <div class="inputBox">
            <el-form :model="loginData" :rules="loginRules" ref="loginForm" class="demo-ruleForm">
              <div class="loginmobie">{{ $fanyi('电子邮件/手机号码') }}</div>
              <el-form-item prop="login_name">
                <el-input v-model="loginData.login_name" :placeholder="$fanyi('请输入邮箱/手机号')"></el-input>
              </el-form-item>
              <div class="loginpassword">{{ $fanyi('密码') }}</div>
              <el-form-item prop="password">
                <el-input v-model="loginData.password" type="password" :show-password="true"
                  :placeholder="$fanyi('请输入密码')" @input="passwordChannelInputLimit" />
              </el-form-item>
            </el-form>
          </div>
          <div class="footer">
            <div class="forgetPassword">
              <div class="fl">
                <el-checkbox v-model="Save_the_password"></el-checkbox>
                <span>{{ $fanyi('保持登录状态') }}</span>
              </div>
              <div class="fr">
                <span class="wangJiMiMa"
                  style="
                                                                                                                                                                                                                                                                                                                                       text-decoration: underline;
                                                                                                                                                                                                                                                                                                                                      cursor: pointer;
                                                                                                                                                                                                                                                                                                                                      color: #ff730b;
                                                                                                                                                                                                                                                                                                                                    "
                  @click="$fun.toPage('/forgetThePassword')">{{
                    $fanyi('忘记密码')
                  }}
                </span>
              </div>
            </div>
            <div class="btnBox">
              <el-button @click="login">{{ $fanyi('登录') }}</el-button>
              <div class="gugedenglu" @click="gugedenglu">
                <img src="../../assets/google.svg" alt="">
                <span>{{ $fanyi('谷歌登录') }}</span>
              </div>
              <div id="google-login-button"></div>
            </div>
            <div class="register">
              <a href="javascript:;" @click="$fun.toPage('/register')">{{
                $fanyi('新会员注册')
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :show-close="false" :close-on-click-modal="false" width="880px">
      <div class="flexAndCenter couponHeader">
        <div class="flexAndCenter couponHeaderLeft">
          <div>{{ $fanyi('信息完善') }}</div>
          <div>（{{ $fanyi('请您填写以下信息，以便完成注册') }}）</div>
        </div>
        <i class="el-icon-close" @click="dialogVisible = false"></i>
      </div>

      <div class="googleRegisterMessageReplenishContainer">
        <div class="googleRegisterMessageReplenishTopContainer flexAndCenter">
          <el-form class="firStepForm" :model="formregister" :rules="formRules" ref="formRef">
            <ul>
              <li>
                <!-- 登录名 -->
                <el-form-item prop="user_last_name" :label="$fanyi('姓氏')">
                  <input class="ipt" v-bind:class="{
                    istrueInput: formInputTrue.user_last_name,
                  }" @focus="validateField('user_last_name')" @blur="validateField('user_last_name')"
                    autocomplete="new-password" v-model="formregister.user_last_name" :placeholder="$fanyi('请输入姓氏')" />
                  <div class="gou" v-if="formInputTrue.user_last_name">✔</div>
                </el-form-item>
              </li>
              <li>
                <!-- 名字 -->
                <el-form-item prop="user_name" :label="$fanyi('名字')">
                  <input v-bind:class="{
                    istrueInput: formInputTrue.user_name,
                  }" class="ipt" @focus="validateField('user_name')" @blur="validateField('user_name')"
                    v-model="formregister.user_name" :placeholder="$fanyi('请输入名字')" />
                  <div class="gou" v-if="formInputTrue.user_name">✔</div>
                </el-form-item>
              </li>
              <li>
                <!-- 国家 -->
                <el-form-item :label="$fanyi('州')" prop="state">
                  <el-select class="xuanZeGuoJia" name="" v-bind:class="{
                    istrueInput: formInputTrue.state,
                  }" filterable @change="validateField('state')" @blur="validateField('state')"
                    v-model="formregister.state" :placeholder="$fanyi('请选择地区')">
                    <el-option :label="item" :value="item" v-for="(item, index) in $imdata.provincesList"
                      :key="item + index">
                    </el-option>
                  </el-select>
                  <div class="gou" v-if="formInputTrue.state" style="margin-right: 20px">
                    ✔
                  </div>
                </el-form-item>
              </li>
              <li>

                <el-form-item :label="$fanyi('SSN（选填）')" prop="user_id_card">
                  <input class="ipt" v-bind:class="{
                    istrueInput:
                      formInputTrue.user_id_card &&
                      ssnStatus === true &&
                      formregister.user_id_card != undefined,
                  }" @focus="validateField('user_id_card')" @blur="validateField('user_id_card')"
                    v-model="formregister.user_id_card" :placeholder="$fanyi('请输入SSN')" />
                  <div class="gou" v-if="formInputTrue.user_id_card &&
                    ssnStatus === true &&
                    formregister.user_id_card != undefined
                  ">
                    ✔
                  </div>
                </el-form-item>
              </li>
              <li class="guoJiaQuHao">
                <!-- 电话 -->
                <el-form-item :label="$fanyi('手机号')" prop="mobile">
                  <div class="shouJIHaoCon">
                    <div class="quHao" v-bind:class="{
                      istrueInput: formInputTrue.mobile,
                    }">
                      <el-select v-model="formregister.countryPhone">
                        <el-option v-for="item in Areacodelist" :key="item.sx" :label="item.value" :value="item.value">
                          {{ $store.state.languagetype == "English" ? item.sx + ' ' + item.value : item.key + ' ' +
                            item.value }}
                        </el-option>
                      </el-select>
                    </div>
                    <input @focus="validateField('mobile')" @blur="validateField('mobile')" class="iptSmall"
                      v-bind:class="{
                        istrueInput: formInputTrue.mobile,
                      }" oninput="this.value=this.value.replace(/[^\d]/g,'')" v-model="formregister.mobile"
                      :placeholder="this.$fanyi('请输入您的手机号')" />
                    <div class="gou" v-if="formInputTrue.mobile">✔</div>
                  </div>
                </el-form-item>
              </li>
              <!-- 验证码 -->
              <li class="yanzhengma">

                <el-form-item :label="$fanyi('验证码')" prop="num">
                  <div class="yanzhengmaBox">
                    <div class="input">
                      <input v-bind:class="{
                        istrueInput: !!formInputTrue.num,
                      }" class="iptSmall" @focus="validateField('num')" @blur="validateField('num')"
                        v-model="formregister.num" :placeholder="this.$fanyi('请输入验证码')" />
                      <div class="gou regCodegou" v-if="formInputTrue.num && show === true">
                        ✔
                      </div>
                      <div class="gou newRegCodegou" v-if="formInputTrue.num && show === false">
                        ✔
                      </div>
                    </div>
                    <button @click.prevent="sendSMS" v-show="show">
                      {{ $fanyi('发送验证码') }}
                    </button>
                    <button v-show="!show" @click.prevent style="opacity: 0.6">
                      {{ Countdown }} S
                    </button>
                  </div>
                </el-form-item>
              </li>
              <li>
                <!-- 密码 -->
                <el-form-item prop="password" :label="$fanyi('密码')" class="regerror">
                  <el-input type="password" class="ipt passWordInputBox" v-bind:class="{
                    elistrueInput: formInputTrue.password,
                  }" @focus="validateField('password')" @blur="validateField('password')" autocomplete="new-password"
                    show-password @input="registerPasswordChannelInputLimit" v-model="formregister.password"
                    :placeholder="$fanyi('请输入密码')" />
                </el-form-item>
              </li>
              <li>
                <!-- 密码确认 -->
                <el-form-item prop="repassword" :label="$fanyi('密码确认')">
                  <el-input class="ipt passWordInputBox" type="password" v-bind:class="{
                    elistrueInput: formInputTrue.repassword,
                  }" @focus="validateField('repassword')" @blur="validateField('repassword')"
                    @input="repasswordChannelInputLimit" autocomplete="new-password" show-password
                    v-model="formregister.repassword" :placeholder="$fanyi('请再次输入密码')" />
                </el-form-item>
              </li>

              <li>
                <el-form-item label="" class="xieYi" style="margin-bottom: 0">
                  <!--                  @click.prevent="getNext"-->
                  <div class="nextBtn" @click.prevent="register">
                    {{ $fanyi('同意规约，完成注册') }}
                  </div>
                  <div class="flexAndCenter" style="justify-content: space-between; width: 100%">
                    <div class="fuWuXieYi">
                      <el-checkbox v-model="agreement"></el-checkbox>
                      <!-- <input class="ocheckBox" type="checkbox" @change="agreement = !agreement" /> -->
                      <a @click="
                        $refs.UserAgreement.$refs.dialog.open(
                          '',
                          () => {
                            agreement = true
                          },
                          () => { }
                        )
                        " href="javascript:;">
                        {{ $fanyi('RAKUMART的用户协议和隐私政策') }}
                      </a>
                    </div>
                    <div class="pageJumpListContainer flexAndCenter">
                      <div @click="$fun.toPage('/register')">
                        {{ $fanyi('新会员注册') }}
                      </div>
                      <div @click="dialogVisible = false">
                        {{ $fanyi('去登录') }}
                      </div>

                    </div>
                  </div>
                </el-form-item>
              </li>
              <li></li>
            </ul>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <UserAgreement ref="UserAgreement"></UserAgreement>

  </div>
</template>

<script>
import GWT from 'jwt-decode'
import UserAgreement from './UserAgreement.vue'
import Areacode from '../../utlis/mobilephoneareacode.js'
export default {

  data() {
    // 校验SSN
    var verificationText = (rule, value, callback) => {
      let str = /^(\w){9}$/
      if (!str.test(value)) {
        this.ssnStatus = false
        callback(new Error(this.$fanyi('SSN输入错误')))
      } else {
        if (value == undefined) {
          this.ssnStatus = false
          callback()
        } else if (value.trim() === '') {
          this.ssnStatus = false
          callback()
        } else {
          callback()
          this.ssnStatus = true
        }
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$fanyi('请再次输入密码')))
      } else if (value !== this.formregister.password) {
        callback(new Error(this.$fanyi('密码输入不一致')))
      } else {
        callback()
      }
    }
    // 邮箱格式检验
    var emailFormatVerify = (rule, value, callback) => {
      let emailPostfixList = [
        'qq.com',
        '163.com',
        'fastmail.com',
        'me.com',
        'email.com',
        'gmail.com',
        '126.com',
        'yahoo.com',
        'sina.com',
        'games.com',
        'rocketmail.com',
        'wow.com',
        'googlemail.com',
        'comcast.net',
        'warwick.net',
        'comcast.net',
        'twcny.rr.com',
        'hotmail.com',
        'foxmail.com',
        'rakumartwork.com',
        'outlook.com',
        'facebook.com',
        'mail.com',
        'mac.com',
        'icloud.com',
        'zoho.com',
        'ymail.com',
        'ygm.com',
        'love.com',
        'aol.com',
        'verizon.net',
        'cs.com',
        'netzero.net',
      ]
      let index = value.indexOf('@')
      let str = value.substr(index + 1, value.length)
      if (emailPostfixList.indexOf(str) === -1) {
        callback(new Error(this.$fanyi('请输入正确的邮箱地址')))
      } else {
        callback()
      }
    }
    // 字段校验
    var FieldCalibration = (rule, value, callback) => {
      this.$api
        .checkRegisterField({
          field: rule.field,
          value: value,
        })
        .then((res) => {
          if (res.success == false) {
            callback(new Error(this.$fanyi('已被使用')))
          } else {
            callback()
          }
        })
    }
    // 对比验证码
    var ContrastVerificationCode = (rule, value, callback) => {
      this.$api
        .checkVerificationCode({
          occasion: 'register',
          number: this.formregister[this.formregister.tools],
          code: value,
        })
        .then((res) => {
          if (res.success == false) {
            this.regcode = false
            callback(new Error(this.$fanyi(res.msg)))
          } else {
            this.regcode = true
            callback()
          }
        })
    }
    return {
      Save_the_password: false,
      loginData: {
        login_name: localStorage.getItem('username')
          ? localStorage.getItem('username')
          : '',
        password: localStorage.getItem('password')
          ? localStorage.getItem('password')
          : '',
      },
      dialogVisible: false,
      agreement: false,
      // 注册表单
      formregister: {
        state: '',
        countryPhone: '+001',
        tools: 'mobile',
      },
      Areacodelist: Areacode,
      loading: false,
      formInputTrue: {},
      ssnStatus: false,
      Countdown: 60,
      show: true,
      formRules: {
        user_last_name: [
          {
            required: true,
            message: this.$fanyi('请输入姓氏'),
            trigger: 'blur',
          },
          {
            required: true,
            min: 1,
            max: 24,
            message: this.$fanyi('姓氏长度不能大于24位'),
            trigger: ['blur', 'change'],
          },
        ],
        user_id_card: [
          {
            pattern: /^(\w){9}$/,
            message: this.$fanyi('SSN输入错误'),
          },
          {
            validator: verificationText,
            trigger: 'blur',
          },
        ],
        state: [
          {
            required: true,
            message: this.$fanyi('请选择州'),
            trigger: ['blur', 'change'],
          },
        ],
        user_name: [
          {
            required: true,
            message: this.$fanyi('请输入名字'),
            trigger: 'blur',
          },
          {
            min: 1,
            max: 24,
            message: '最长支持24个字符',
            trigger: ['blur', 'change'],
          },
        ],
        mobile: [
          {
            required: true,
            message: this.$fanyi('手机号不能为空'),
            trigger: 'blur',
          },
          {
            required: true,
            pattern: /^(\w){10}$/,
            message: this.$fanyi('请输入正确的手机号'),
          },
          {
            validator: FieldCalibration,
            trigger: 'blur',
          },
        ],
        num: [
          {
            required: true,
            message: this.$fanyi('请填写验证码'),
            trigger: 'blur',
          },
          {
            validator: ContrastVerificationCode,
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: this.$fanyi('请输入正确的邮箱地址'),
            trigger: 'blur',
          },
          {
            required: true,
            pattern: /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/,
            message: this.$fanyi('请输入正确的邮箱地址'),
            trigger: ['blur', 'change'],
          },
          {
            validator: FieldCalibration,
            trigger: 'blur',
          },
          {
            validator: emailFormatVerify,
            trigger: 'blur',
          },
        ],
        password: [
          //请输入6-16位密码 至少包含大小写字母和数字其中一种
          {
            required: true,
            pattern: /^.{6,16}$/,
            message: this.$fanyi('请输入6-请输入6-16位包含大小写字母和数字的密码'),
          },
        ],
        repassword: [
          {
            required: true,
            validator: validatePass2,
            trigger: ['blur', 'change'],
          },
        ],
      },
      loginRules: {
        login_name: [
          {
            required: true,
            message: this.$fanyi('请输入邮箱/手机号'),
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: this.$fanyi('请输入密码'),
            trigger: 'blur',
          },
        ],
      },
    }
  },
  components: {
    UserAgreement,
  },
  created() {

    let that = this
    document.onkeypress = function (e) {
      var keycode = document.all ? event.keyCode : e.which
      if (keycode == 13) {
        that.login() // 登录方法名
        return false
      }
    }
    localStorage.getItem('keep') == 1
      ? (this.Save_the_password = true)
      : (this.Save_the_password = false)
  },
  mounted() {
    window.addEventListener('load', () => {
      if (typeof window.google === 'object') {
        google.accounts.id.initialize({
          client_id:
            '265938311898-hspa2pt9b5il4jbti042jiteb7uuo58d.apps.googleusercontent.com',
          callback: this.handleCredentialResponse,
        })

        google.accounts.id.renderButton(
          document.getElementById('google-login-button'),
          { theme: 'outline', size: 'large' } // customization attributes
        )
        google.accounts.id.prompt() // also display the One Tap dialog
      }

    })

  },
  methods: {
    gugedenglu() {
      this.$nextTick(() => {
        if (document.querySelector('.nsm7Bb-HzV7m-LgbsSe-MJoBVe')) {
          document.querySelector('.nsm7Bb-HzV7m-LgbsSe-MJoBVe').click()

        } else {
          location.reload()
          return
        }

      })
    },

    handleExportExcel() {

      require.ensure([], () => {

        const { export_json_to_excel } = require("../../utlis/Export2Excel");



        const tHeader = [

          "NO",

          "客户ID",

          "客户名",

          "会员等级",

          "担当",

          "采购",

          "配货",

          "客户状态",

          "最后下单时间",

          "最后访问时间",

          "所在群组",

        ];

        const filterVal = [

          "name",

          "team",

          "reparationFact",

          "userNum",

          "reparationMemberCharge",

          "reparationGroupCharge",

          "reparationDepartmentCharge",

          "reparationCompanyCharge",

        ];

        const list = this.tableData; //把data里的tableData存到list 

        const data = this.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "客户列表");

      });

    },
    formatJson(filterVal, jsonData) {

      return jsonData.map((v) => filterVal.map((j) => v[j]));

    },
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // 记住密码
          if (this.Save_the_password == true) {
            localStorage.setItem('username', this.loginData.login_name)
            localStorage.setItem('password', this.loginData.password)
            localStorage.setItem('keep', 1)
          } else {
            localStorage.removeItem('username')
            localStorage.removeItem('password')
            localStorage.removeItem('keep')
          }
          this.$api
            .logins({
              login_account: this.loginData.login_name,
              password: this.loginData.password,
            })
            .then((res) => {
              if (!res.success)
                return this.$message.error(
                  this.$fanyi('邮箱、手机号或密码输入错误')
                )
              localStorage.setItem('user_token', res.data.user_token)
              this.$api.EuropegetUserInfo().then((res) => {
                this.$store.commit('userData', res.data)
                this.$router.replace('/')
              })
              this.$store.dispatch('goodsToCartNum')
            })
        }
      })
    },
    // 获取·用户token
    async handleCredentialResponse(response) {

      if (response.credential) {
        const data = GWT(response.credential)

        const res = await this.$api.userGoogleLogin({
          google_token: data,
        })

        if (res.data.user_token) {
          localStorage.setItem('user_token', res.data.user_token)
          this.$api.EuropegetUserInfo().then((res) => {
            this.$store.commit('userData', res.data)
            this.$router.replace('/')
          })
          this.$store.dispatch('goodsToCartNum')
        }
        if (res.data.user_id) {
          this.user_id = res.data.user_id
          this.dialogVisible = true
        }
        // if (!res.success) return this.$message.error(this.$fanyi(res.message))
        // localStorage.setItem('user_token', res.data.user_token)
        // this.$api.EuropegetUserInfo().then((res) => {
        //   this.$store.commit('userData', res.data)
        //   this.$router.replace('/')
        // })
        // this.$store.dispatch('goodsToCartNum')
      }

      // let googleToken = response.credential;

      // return false;
    },
    // 谷歌登录注册
    register() {
      this.$refs.formRef.validate((valid) => {

        if (valid) {
          if (!this.agreement) {
            return this.$message.error(
              this.$fanyi('使用条款和隐私政策还没有被同意。')
            )
          }
          var list = {
            user_id: this.user_id,
            user_last_name: this.formregister.user_last_name, //登录名
            password: this.formregister.password, //密码
            user_name: this.formregister.user_name, //真实姓名
            email: this.formregister.email, //邮箱
            mobile: this.formregister.mobile, //手机号
            state: this.formregister.state,
            user_id_card: this.formregister.user_id_card, //身份证号
            // verification: {
            //   number:
            //     this.formregister.tools == 'mobile'
            //       ? this.formregister[this.formregister.tools]
            //       : this.formregister[this.formregister.tools],
            //   code: this.formregister.num,
            // },
          }

          this.$api.informationCompletion(list).then((res) => {

            if (res.code !== 0)
              return this.$message.error(this.$fanyi(res.message))
            if (res.success) {
              localStorage.setItem('user_token', res.data.user_token)
              this.$api.EuropegetUserInfo().then((res) => {
                this.$store.commit('userData', res.data)
                this.$router.replace('/')
              })
              this.$store.dispatch('goodsToCartNum')
              this.dialogVisible = false
            }
          })
        }
      })
    },
    registerPasswordChannelInputLimit(e) {
      this.formregister.password = e.replace(/[^A-Za-z0-9~!@#$%^+-.]/g, '')
    },
    repasswordChannelInputLimit(e) {
      this.formregister.repassword = e.replace(/[^A-Za-z0-9~!@#$%^+-.]/g, '')
    },
    passwordChannelInputLimit(e) {
      // this.data.expressNumber = e.replace(/[^A-Za-z0-9]/g, '');
      this.loginData.password = e.replace(/[^A-Za-z0-9~!@#$%^+-.]/g, '')
    },
    // 手动触发表单验证
    validateField(type) {
      if (this.formregister[type])
        this.formregister[type] = this.formregister[type].trim()
      switch (type) {
        case 'password':
          this.$refs.formRef.validateField('repassword', (v) => {
            if (v == '') {
              this.formInputTrue['repassword'] = true
            } else {
              this.formInputTrue['repassword'] = false
            }
            this.$forceUpdate()
          })
          this.$refs.formRef.validateField(type, (v) => {
            if (v == '') {
              this.formInputTrue[type] = true
            } else {
              this.formInputTrue[type] = false
            }
            this.$forceUpdate()
          })
          break
        default:
          this.$refs.formRef.validateField(type, (v) => {
            if (v == '') {
              this.formInputTrue[type] = true
            } else {
              this.formInputTrue[type] = false
            }
            this.$forceUpdate()
          })
      }
    },
    // 从第一步到第二步
    async getNext() {
      if (!this.agreement)
        return this.$message.error(
          this.$fanyi('使用条款和隐私政策还没有被同意。')
        )
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.formRegister()
        }
      })
      var params = new FormData()

    },
    // 短信验证
    sendSMS() {
      if (!!!this.formregister[this.formregister.tools]) {
        this.$message.warning(this.$fanyi('请输入您的手机号'))
        return false
      }
      this.$refs.formRef.validateField('mobile', (mobileError) => {
        // 2种情况：emailError ='请输入邮箱'或者emailError =''（所以返回值为空时，验证通过。返回值非空时，验证失败）
        if (!mobileError) {
          let list = {
            occasion: 'register',
            tool: this.formregister.tools,
          }
          if (this.formregister.tools == 'email') {
            list.number = this.formregister.email
          } else {
            list.number = this.formregister.mobile
            list.area_code = this.formregister.countryPhone
          }
          this.show = false
          this.Countdown = 60
          this.$api.sendVerificationCode(list).then((res) => {
            this.show = true
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
            // 60秒倒计时
            this.show = false
            var timer = setInterval(() => {
              this.Countdown--
              if (this.Countdown <= 0) {
                this.show = true
                this.Countdown = 60
                clearInterval(timer)
              }
            }, 1000)
          })
        }
      })
    },
    // 确认注册
    formRegister() {
      // this.ip = sessionStorage.getItem("ip");

      var list = {
        user_last_name: this.formregister.user_last_name, //登录名
        password: this.formregister.password, //密码
        user_name: this.formregister.user_name, //真实姓名
        email: this.formregister.email, //邮箱
        mobile: this.formregister.mobile, //手机号
        user_id_card: this.formregister.user_id_card, //身份证号
        area_code: this.formregister.countryPhone,// 区号，
        verification: {
          number:
            this.formregister.tools == 'mobile'
              ? this.formregister[this.formregister.tools]
              : this.formregister[this.formregister.tools],
          code: this.formregister.num,
        },
      }
      // 假如是讲师系统推荐链接进入就把邀请码也放进去
      if (this.$route.query.promote) {
        list.promote = this.$route.query.promote
      }
      // this.loading = true;
      // this.$api.register(list).then((res) => {
      //   this.loading = false;
      //   if (res.code != 0) return;
      //   this.step = 2;
      // });
    },
  },
}
</script>
<style lang="scss">
.gugedenglu {
  margin-top: 20px;



  justify-content: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 380px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #DEDEDE;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }

  span {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #222222;
  }
}

#google-login-button {

  margin-top: 20px;
  display: none;


  justify-content: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 380px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 6px;
  /* border: 1px solid #DEDEDE; */
  align-items: center;
  border-radius: 0;
  display: none;
}

.S9gUrf-YoZ4jf {
  width: 380px;
  height: 40px;

}

.nsm7Bb-HzV7m-LgbsSe-Bz112c {
  margin-left: 80px;

}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  height: 18px;
  margin-right: -80px;
  min-width: 18px;
  width: 18px;
}

.nsm7Bb-HzV7m-LgbsSe-BPrWId {
  padding: 0px;

}

/* #google-login-button>>>.S9gUrf-YoZ4jf>.nsm7Bb-HzV7m-LgbsSe {
  width: 380px;
  height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 0;

} */
</style>
<style lang="scss" scoped>
@import '../../css/mixin.scss';

/deep/ .el-dialog {
  box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
  border-radius: 6px;

  /deep/ .el-input__inner {
    height: 48px;
    border-radius: 6px !important;
  }
}

.ipt {
  border-radius: 6px !important;
}

.quHao,
.iptSmall {
  border-radius: 6px !important;
}

.xuanZeGuoJia {
  border-radius: 6px !important;

  /deep/ .el-input__inner {
    height: 48px;
    border-radius: 6px !important;
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 59%;
  bottom: 0;

  img {
    width: 100%;
    height: 100%;
    min-height: 1080px;
  }
}

/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0;
}

.couponHeader {
  justify-content: space-between;
  padding: 0 30px 0 20px;
  border-bottom: 1px solid #f5f5f5;
  height: 68px;

  .couponHeaderLeft {
    div:first-child {
      font-size: 18px;
      color: #222;
      font-weight: 500;
    }

    div:last-child {
      font-size: 12px;
      color: #999;
    }
  }

  .el-icon-close {
    color: #000;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
}

.googleRegisterMessageReplenishTopContainer {
  padding: 30px;

  .firStepForm {
    /deep/ .el-input {
      border-radius: 6px;
    }

    /deep/.el-form-item__error {
      top: 50px;
    }

    >ul {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;

      >li {
        width: 379px;
        display: flex;
        flex-direction: column;

        .passWordInputBox {
          /deep/.el-input__inner {
            height: 48px;

            border-radius: 6px;
          }
        }

        /deep/.el-form-item {
          margin-bottom: 30px;
        }

        // 表单标题样式
        /deep/.el-form-item__label {
          padding: 0;
          text-align: left;
          float: none;
          display: block;
          width: 100%;
          line-height: 18px;
          height: 18px;
          margin-bottom: 10px;

          &::before {
            display: none;
          }
        }

        /deep/.elistrueInput .el-input__inner {
          background: rgba(255, 115, 11, 0.1);
          border: 1px solid #ff730b;
          border-radius: 0px;
          position: relative;
        }

        input {
          width: 379px;
          height: 48px;
          background: #ffffff;
          border: 1px solid #dedede;

          border-radius: 0px;
          padding: 0 10px;
          transition: 0.4s;

          &::placeholder {
            color: #b6b6b6;
          }

          &:focus {
            border: 1px solid #ff730b;
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
          }

          &.istrueInput {
            background: rgba(255, 115, 11, 0.1);
            border: 1px solid #ff730b;
            border-radius: 0px;
            position: relative;
          }
        }

        .gou {
          position: absolute;
          font-weight: bold;
          right: 13px;
          top: 3px;
          color: #ff730b;

          &.regCodegou {
            right: 166px;
          }

          &.newRegCodegou {
            right: 50px;
          }
        }

        .xuanZeGuoJia {
          width: 100%;
          height: 48px;
          background: rgba(255, 115, 11, 0.1);
          border-radius: 0px;

          /deep/.el-input__inner {
            width: 100%;
            height: 48px;
            border-radius: 0px;
            //border: none;
          }

          /deep/.el-icon-arrow-up {
            font-weight: bold;
            font-size: 15px;
          }
        }

        .istrueInput {
          background: rgba(255, 115, 11, 0.1) !important;
          border: 1px solid #ff730b !important;
          border-radius: 0px;
          position: relative;

          /deep/.el-input__inner {
            width: 100%;
            height: 46px;

            border-radius: 0px;
            background: rgba(255, 115, 11, 0.1) !important;
            border: none;
          }
        }
      }

      .guoJiaQuHao {
        .shouJIHaoCon {
          display: flex;
          align-items: center;

          .quHao {
            width: 100px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #dedede;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            display: flex;
            align-items: center;

            /deep/ .el-select {
              width: 90%;
              height: 40px;

              border-radius: 4px;
              border: none;


              input {
                border: none !important;
                font-size: 14px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #222222;
                line-height: 16px;
                height: 40px;
                padding-left: 8px;
                padding-right: 8px;
                border-radius: 4px;


              }




            }

            &.istrueInput {
              background: rgba(255, 115, 11, 0.1);
              border: 1px solid #FF730B;
              border-radius: 6px;
              position: relative;
            }
          }

          input {
            width: 270px;
          }
        }
      }

      .yanzhengma {
        .yanzhengmaBox {
          display: flex;
          align-items: center;
          border-radius: 6px;

          input {
            height: 48px;
            background: #ffffff;
            border: 1px solid #dedede;
            border-radius: 6px;
          }

          button {
            height: 48px;
            background: transparent;
            position: absolute;
            right: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #ff730b;
            line-height: 20px;
          }
        }
      }

      .xieYi {
        display: flex;
        align-items: center;

        /deep/ .el-form-item__content {
          width: 800px;
          display: flex;
          flex-direction: column;
          min-width: 800px;
        }

        .nextBtn {
          min-width: 379px;
          width: 379px;
          height: 48px;
          background: #ff730b;
          font-size: 14px;
          color: #ffffff;
          line-height: 48px;
          text-align: center;
        }

        .fuWuXieYi {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          //width: 800px;
          //min-width: 800px;
          /deep/.el-checkbox {
            height: 20px !important;
          }

          /deep/.el-checkbox__inner {
            margin-top: -14px;
            border-radius: 50%;
            width: 20px;
            height: 20px !important;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }

          a {
            width: 480px;
            height: 20px;
            font-size: 12px;
            color: #222222;
            line-height: 20px;
            margin: -9px 5px 0 10px;
          }
        }

        .pageJumpListContainer {
          div {
            font-size: 14px;
            text-decoration: underline;
            cursor: pointer;
          }

          div:first-child {
            color: #222222;
            margin-right: 30px;
          }

          div:last-child {
            color: #ff730b;
          }
        }
      }
    }

    .showregisterInfo {
      width: 379px;
      height: 48px;
      font-size: 13px;
      line-height: 48px;
      border: 1px solid #dedede;
      background-color: #f3f3f3;
      // color: #787777;
      border-radius: 0px;
      padding: 0 10px;
      transition: 0.4s;
    }
  }
}

.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../assets/login/7a9798c1-d5ee-4c96-9f1b-626184076892.png') no-repeat;
  background-size: cover;

  .loginMain {
    display: flex;
    border-radius: 6px;

    .loginMainRight {
      display: flex;
      justify-content: center;
      background-color: white;
      align-items: center;
      flex: 1;
      position: relative;
      border-radius: 6px;

      .loginBox {
        position: relative;
        width: 480px;
        height: 684px;
        background: #ffffff;
        border-radius: 6px;

        .top-pic {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 194px;

          img {
            width: 115px;
            height: 74px;
          }
        }

        .loginmobie,
        .loginpassword {
          margin-bottom: 10px;
          font-size: 16px;
          font-family: Roboto;
          font-weight: 400;
          color: #222222;
        }

        .inputBox {
          padding: 0 50px;

          /deep/ .el-input__inner {
            width: 380px;
            height: 40px;
            background: #ffff !important;

            border-radius: 6px;
            font-size: 14px;
          }
        }

        .btnBox {
          margin-top: 50px;

          /deep/ .el-button {
            width: 380px;
            height: 40px;
            background: #ff730b;
            font-size: 14px;
            border-radius: 6px;
            color: white;
            border: none;
          }
        }

        .footer {
          padding: 0 50px;
        }

        .forgetPassword {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 24px;

          /deep/ .el-checkbox__input {
            height: 16px;

            .el-checkbox__inner {
              border-radius: 50%;
            }
          }
        }

        .register {
          margin-top: 30px;
          text-align: center;

          a {
            font-size: 14px;
            color: #ff730b;
            line-height: 20px;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
